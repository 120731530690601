// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigDev = {
  apiKey: "AIzaSyCM2j8NJrLrCBM17HA2j5gYcA4dt9YPYcs",
  authDomain: "xtwo-dev.firebaseapp.com",
  projectId: "xtwo-dev",
  storageBucket: "xtwo-dev.appspot.com",
  messagingSenderId: "289447958958",
  appId: "1:289447958958:web:24aced34e75b8cdea0503f",
  measurementId: "G-PSDJKDHG8L",
};

const firebaseConfigProd = {
  apiKey: "AIzaSyDsmHlRJVHy2Om_IoNtJptrQYRD_xzOMJk",
  authDomain: "xtwo-d3a3e.firebaseapp.com",
  projectId: "xtwo-d3a3e",
  storageBucket: "xtwo-d3a3e.appspot.com",
  messagingSenderId: "626025182339",
  appId: "1:626025182339:web:96515af60e50c5383457b6",
  measurementId: "G-FZ5K7K0RKF",
};

export const isDevelopment = window.location.hostname === "localhost";
const config = isDevelopment ? firebaseConfigDev : firebaseConfigProd;

// Initialize Firebase
const app = initializeApp(config);
