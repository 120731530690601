import {
  Button,
  Container,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function Hero() {
    const { t, i18n } = useTranslation();

  return (
    <Container maxW={"5xl"}>
      <Stack
        textAlign={"center"}
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: "3xl", sm: "4xl", md: "5xl" }}
          lineHeight={"110%"}
        >
          {t("集計つきアンケートを無料で作成")}
        </Heading>
        <Text
          color={"gray.500"}
          maxW={"3xl"}
          fontSize={{ base: "xl", sm: "xl", md: "4xl" }}
        >
          {t("TwitterやSlackで投票や意見をあつめたい！")}
          <br />
          {t("そんなときにアンケートをすぐ作れます")}
        </Text>
        <Text color={"gray.500"} maxW={"2xl"}>
          {t("ユーザー登録は一切不要。SNS/チャットでそのまま拡散できる画像つきのURLなので簡単に目に付きます。")}
        </Text>
        <Stack spacing={6} direction={"row"}>
          <Button
            px={6}
            colorScheme={"orange"}
            bg={"orange.400"}
            _hover={{ bg: "orange.500" }}
            as="a"
            href="#create"
          >
            {t("さっそく作成してみる")}
          </Button>
        </Stack>
        <Image
          maxW="776px"
          w="100%"
          alt="hero"
          objectFit='cover'
          src="images/hero.png"
          borderRadius={"xl"}
          boxShadow={"xl"}
        />
      </Stack>
    </Container>
  );
}
