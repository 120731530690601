import { ChartData } from "chart.js";
import { PageDefintion } from "./type";

export function appendResult(
  def: PageDefintion,
  input: number[][],
  axis: number,
  base: ChartData<"bar">[]
) {
  const ret: ChartData<"bar">[] = [];
  if (def.questions.length === 1) {
    const target = 0;
    const barData = base[0];
    ret.push(makeChartDataRaw(input, target, barData));
  } else {
    for (let target = 1; target < def.questions.length; target++) {
      const barData = base[target - 1];
      ret.push(pivotRaw(input, axis, target, barData));
    }
  }
  return ret;
}

export function makeResult(
  def: PageDefintion,
  input: number[][],
  axis: number
) {
  const ret: ChartData<"bar">[] = [];
  if (def.questions.length === 1) {
    const target = 0;
    const barData: ChartData<"bar"> = {
      labels: def.questions[target].choices,
      datasets: [
        {
          data: new Array<number>(def.questions[target].choices.length).fill(0),
        },
      ],
    };
    ret.push(makeChartDataRaw(input, target, barData));
  } else {
    for (let target = 1; target < def.questions.length; target++) {
      const barData: ChartData<"bar"> = {
        labels: def.questions[axis].choices,
        datasets: [],
      };
      def.questions[target].choices.forEach((c) => {
        barData.datasets.push({
          label: c,
          data: new Array<number>(def.questions.length).fill(0),
        });
      });
      ret.push(pivotRaw(input, axis, target, barData));
    }
  }
  return ret;
}

function makeChartDataRaw(
  input: number[][],
  target: number,
  barData: ChartData<"bar">
) {
  const ret = input.reduce((prev, d) => {
    prev.datasets[0].data[d[target]]++;
    return prev;
  }, barData);
  return ret;
}

function pivotRaw(
  input: number[][],
  axis: number,
  target: number,
  barData: ChartData<"bar">
): ChartData<"bar"> {
  const ret = input.reduce((prev, d) => {
    prev.datasets[d[target]].data[d[axis]]++;
    return prev;
  }, barData);
  return ret;
}
