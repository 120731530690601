import { Timestamp } from "firebase/firestore";

export interface QuestionDefinition {
  heading: string;
  choices: string[];
}

export function isEmpty(qd: QuestionDefinition): boolean {
  let empty = true;
  if (qd.heading !== "") empty = false;
  qd.choices.forEach((c) => {
    if (c !== "") empty = false;
  });
  return empty;
}

export function clean(qd: QuestionDefinition): QuestionDefinition {
  const ret: QuestionDefinition = {
    heading: qd.heading.trim(),
    choices: [],
  };

  qd.choices.forEach((c) => {
    if (c.trim() !== "") {
      ret.choices.push(c.trim());
    }
  });
  for (let i = 0; ret.choices.length < qd.choices.length; i++) {
    ret.choices.push("");
  }
  return ret;
}

export interface PageDefintion {
  summary: string;
  questions: QuestionDefinition[];
  createdAt: Timestamp;
}

export interface Page {
  phase: "survey" | "send" | "ad" | "result";
  num: number;
}

export interface Response {
  visitorId: string;
  data: number[];
  createdAt: Timestamp;
}
