import { Button } from "@chakra-ui/button";
import { FormControl, FormHelperText } from "@chakra-ui/form-control";
import { Box, Center, Flex, Stack } from "@chakra-ui/layout";
import { Icon, useTheme } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/textarea";
import {
  doc,
  getFirestore,
  serverTimestamp,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { nanoid } from "nanoid";
import {
  default as React,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { MdOpenInNew, MdPostAdd } from "react-icons/md";
import { arrayMove, List } from "react-movable";
import Swal from "sweetalert2";
import "./App.css";
import { CreateQ } from "./CreateQ";
import Hero from "./Hero";
import { clean, isEmpty, PageDefintion, QuestionDefinition } from "./type";

function fixedFillText(
  context: CanvasRenderingContext2D,
  text: string,
  width: number,
  lineHight: number,
  align: string
) {
  var column = [""],
    line = 0;

  for (let i = 0; i < text.length; i++) {
    var char = text.charAt(i);
    if (context.measureText(column[line] + char).width > width) {
      line++;
      column[line] = "";
    }
    column[line] += char;
  }

  let padding: any;
  for (let i = 0; i < column.length; i++) {
    let lineWidth = context.measureText(column[i]).width;
    if (align === "right") {
      padding = width - lineWidth;
    } else if (align === "center") {
      padding = (width - lineWidth) / 2;
    } else {
      padding = 0;
    }
    if (column.length === 1) padding = 0;
    context.fillText(
      column[i],
      600 - padding,
      315 - (lineHight * (column.length - 1)) / 2 + lineHight * i
    );
  }
}

function Preview({ text }: { text: string }) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const getContext = (): CanvasRenderingContext2D => {
    const canvas = canvasRef.current!;
    canvas.style.width = "100%";
    canvas.style.height = "100%";
    canvas.width = 1200;
    canvas.height = 630;
    return canvas.getContext("2d")!;
  };
  useEffect(() => {
    const ctx: CanvasRenderingContext2D = getContext();
    const bg = new Image();
    bg.src = "/images/ogpbg.png";
    bg.onload = function () {
      ctx.drawImage(bg, 0, 0);
      ctx.font = "40pt sans-serif"
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      fixedFillText(ctx, text, 900, 70, "center");
      ctx.save();
    };
  });

  return (
    <div>
      <canvas className="canvas" ref={canvasRef} />
    </div>
  );
}

export function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
}

export function Create() {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [sendingButton, setSendingButton] = useState(false);
  const sending = useRef(false);
  const summaryLimit = 90;

  const emptyQuestion = {
    heading: "",
    choices: ["", ""],
  };

  const [pageDef, setPageDef] = useState<PageDefintion>({
    summary: "",
    questions: [emptyQuestion],
    createdAt: serverTimestamp() as Timestamp,
  });

  const preview = useDebounce(pageDef.summary, 1000);
  const MemoPreview = useMemo(() => {
    return (
      <Preview
        text={preview === "" ? t("プレビューがここに表示されます") : preview}
      ></Preview>
    );
  }, [preview]);

  async function submit() {
    if (sending.current) return;
    sending.current = true;
    setSendingButton(true);

    const cleanPageDef: PageDefintion = getSubmitPageDef();
    if (cleanPageDef.questions.length < 1) {
      Swal.fire({
        icon: "error",
        text: t("最低1つの質問が必要です"),
        iconColor: theme.colors.brand[500],
        confirmButtonColor: theme.colors.brand[500],
      });
      sending.current = false;
      setSendingButton(false);
      return;
    }
    let errorExist = false;
    let errorQText = "";
    cleanPageDef.questions.forEach((q, i) => {
      if (q.choices.length < 2) {
        errorExist = true;
        errorQText += `（${t("質問")}${i + 1}）`;
      }
    });
    if (errorExist) {
      Swal.fire({
        icon: "error",
        text: t(`最低2つの選択肢が必要です`) + errorQText,
        iconColor: theme.colors.brand[500],
        confirmButtonColor: theme.colors.brand[500],
      });
      sending.current = false;
      setSendingButton(false);
      return;
    }

    try {
      const db = getFirestore();
      const id = nanoid(10);
      const pageDefRef = doc(db, "pageDef", id);
      await setDoc(pageDefRef, cleanPageDef);

      window.dataLayer.push({
        event: "created",
      });
      
      window.open("/r/" + id);
      sending.current = false;
      setSendingButton(false);
    } catch (err: any) {
      Swal.fire({
        icon: "error",
        text: err,
        iconColor: "brand",
        confirmButtonColor: "brand",
      });
      sending.current = false;
      setSendingButton(false);
    }
  }

  function getSubmitPageDef() {
    const cleanPageDef: PageDefintion = {
      summary: pageDef.summary.trim(),
      questions: [],
      createdAt: serverTimestamp() as Timestamp,
    };
    pageDef.questions.forEach((q) => {
      if (!isEmpty(q)) {
        const cleanQ = clean(q);
        cleanQ.choices = cleanQ.choices.filter((c) => c.trim() !== "");
        cleanPageDef.questions.push(cleanQ);
      }
    });
    return cleanPageDef;
  }

  const onChangeSummary = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (event.target.value.length > summaryLimit) return;
      setPageDef({ ...pageDef, summary: event.target.value });
    },
    [pageDef]
  );

  return (
    <>
      <Box>
        <Center>
          <Flex p={3} maxW={800} w={"100vw"}>
            <img
              alt="SurveyZero logo"
              src="/images/logo.svg"
              width="120"
              height="30"
            ></img>
            <Box flex={1}></Box>
          </Flex>
        </Center>
        <Hero />
        <Center>
          <Stack id="create">
            <Box w={"100vw"} maxW={800} p={{ sm: 3 }}>
              <Box bg="white" borderWidth="1px" p={3} rounded={"md"} mb={3}>
                <FormControl>
                  <Textarea
                    variant={"filled"}
                    value={pageDef.summary}
                    onChange={onChangeSummary}
                    placeholder={t("Twitter/Facebook投稿に表示される内容")}
                  />
                  <FormHelperText
                    fontSize={"10px"}
                    position={"absolute"}
                    bottom={1}
                    right={1}
                    textAlign="right"
                  >
                    {pageDef.summary.length}/{summaryLimit}
                  </FormHelperText>
                </FormControl>
                <Box mt={3} rounded={"md"} overflow="hidden">
                  {MemoPreview}
                </Box>
              </Box>
              <List
                values={pageDef.questions}
                onChange={({ oldIndex, newIndex }) =>
                  setPageDef({
                    ...pageDef,
                    questions: arrayMove(pageDef.questions, oldIndex, newIndex),
                  })
                }
                renderList={({ children, props }) => (
                  <Stack spacing={3} {...props}>
                    {children}
                  </Stack>
                )}
                renderItem={({ value, props, index, isDragged }) => (
                  <div {...props}>
                    <CreateQ
                      qid={index!}
                      value={value}
                      onChange={(qd: QuestionDefinition) => {
                        const questions = pageDef.questions.concat();
                        questions[index!] = qd;
                        setPageDef({ ...pageDef, questions: questions });
                      }}
                      isDragged={isDragged}
                    ></CreateQ>
                  </div>
                )}
              />
              {pageDef.questions.length < 4 && (
                <Stack>
                  <Button
                    variant={"outline"}
                    mt={3}
                    onClick={() => {
                      setPageDef({
                        ...pageDef,
                        questions: [...pageDef.questions, emptyQuestion],
                      });
                    }}
                  >
                    <Icon fontSize={25} color={"blackAlpha.00"}>
                      <MdPostAdd></MdPostAdd>
                    </Icon>
                  </Button>
                </Stack>
              )}
            </Box>

            <Box p={{ sm: 3 }}>
              <Button
                w={"full"}
                colorScheme="brand"
                onClick={() => {
                  submit();
                }}
                isLoading={sendingButton}
                rightIcon={<MdOpenInNew></MdOpenInNew>}
              >
                {t("作成する")}
              </Button>
            </Box>
            <Box h={20}></Box>
          </Stack>
        </Center>
      </Box>
    </>
  );
}
