import { ChartData, ChartOptions } from "chart.js";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Box, useTheme } from "@chakra-ui/react";
import { splitByLength } from "./Result";

export function SimpleResult({
  title,
  data,
}: {
  title: string;
  data: ChartData<"bar">;
}) {
  const theme = useTheme();
  const windowDimensions = useWindowDimensions();

  const view = data;
  const color = theme.colors.brand;

  view.datasets = view.datasets.map((d: any, i: number) => {
    return {
      ...d,
      backgroundColor: new Array<string>(view.labels?.length || 0).fill(
        color[500]
      ),
      hoverBackgroundColor: new Array<string>(view.labels?.length || 0).fill(
        color[500]
      ),
    };
  });

  const isHorizontal =
    windowDimensions.height < windowDimensions.width || // 縦横比率
    windowDimensions.width > 500; // PC表示の場合を想定

  const barOptions = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: splitByLength(title, 30),
      },
      datalabels: {
        color: "white",
        font: {
          weight: "bold",
        },
      },
    },
    indexAxis: isHorizontal ? ("y" as const) : ("x" as const),
    scales: {
      y: {
        ticks: {
          callback: function (label: number) {
            return !isHorizontal
              ? label
              : splitByLength(this.getLabelForValue(label), 10);
          },
          stepSize: 1,
        },
      },
      x: {
        ticks: {
          minRotation: isHorizontal ? undefined : 90,
          callback: function (label: number) {
            return isHorizontal
              ? label
              : splitByLength(this.getLabelForValue(label), 10);
          },
          stepSize: 1,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  } as ChartOptions<"bar">;

  const height = Math.min(
    windowDimensions.height - 90,
    Math.min(windowDimensions.height, windowDimensions.width) * 2,
    Math.max(windowDimensions.height, windowDimensions.width),
    500 // PC表示の場合を想定、そんなにでかくても見ずらい
  );

  return (
    <Box h={height} minW={300} minH={450}>
      <Bar options={barOptions} data={view} />
    </Box>
  );
}

export const useWindowDimensions = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);
  return windowDimensions;
};
