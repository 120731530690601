import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import colorbrewer from "colorbrewer";
import React from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Box } from "@chakra-ui/react";
import { useWindowDimensions } from "./SimpleResult";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartjsPluginStacked100,
  ChartDataLabels
);

export function splitByLength(str: string, length: number) {
  if (!str || !length || length < 1) {
    return [];
  }
  var regexPattern = new RegExp(
    "(?:[\uD800-\uDBFF][\uDC00-\uDFFF]|[^\uD800-\uDFFF]){1," + length + "}",
    "g"
  );

  return str.match(regexPattern) || [];
}

export function Result({
  title,
  axis,
  data,
}: {
  title: string;
  axis: string;
  data: ChartData<"bar">;
}) {
  const windowDimensions = useWindowDimensions();
  const isHorizontal =
    windowDimensions.height < windowDimensions.width || // 縦横比率
    windowDimensions.width > 500; // PC表示の場合を想定

  const colors =
    colorbrewer.Paired[data.datasets.length < 3 ? 3 : data.datasets.length];

  const view = data;
  view.datasets = view.datasets.map((d: any, i: number) => {
    return {
      ...d,
      backgroundColor: colors[i],
      hoverBackgroundColor: colors[i],
    };
  });

  const barOptions_stacked = {
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: splitByLength(title, 30),
      },
      stacked100: { enable: true, replaceTooltipLabel: false },
      datalabels: {
        color: "white",
        font: {
          weight: "bold",
        },
        display: function (context) {
          const data = context.chart.data as any;
          const { datasetIndex, dataIndex } = context;
          return data.calculatedData[datasetIndex][dataIndex] > 10;
        },
        formatter: (_value, context) => {
          const data = context.chart.data as any;
          const { datasetIndex, dataIndex } = context;
          return `${data.calculatedData[datasetIndex][dataIndex]}%`;
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const data = tooltipItem.chart.data as any;
            const datasetIndex = tooltipItem.datasetIndex;
            const index = tooltipItem.dataIndex;
            const datasetLabel = data.datasets[datasetIndex].label || "";
            // You can use two type values.
            // `data.originalData` is raw values,
            // `data.calculatedData` is percentage values, e.g. 20.5 (The total value is 100.0)
            // const originalValue = data.originalData[datasetIndex][index];
            const rateValue = data.calculatedData[datasetIndex][index];
            return `${datasetLabel}: ${rateValue}%`;
          },
        },
      },
    },
    indexAxis: isHorizontal ? ("y" as const) : ("x" as const),
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: { text: splitByLength(axis, 30), display: !isHorizontal },
        stacked: true,
        ticks: {
          minRotation: isHorizontal ? undefined : 90,
          callback: function (label: number) {
            return isHorizontal
              ? label.toString() + "%"
              : splitByLength(this.getLabelForValue(label), 10);
          },
        },
      },
      y: {
        title: { text: splitByLength(axis, 30), display: isHorizontal },
        stacked: true,
        ticks: {
          callback: function (label: number) {
            return !isHorizontal
              ? label.toString() + "%"
              : splitByLength(this.getLabelForValue(label), 10);
          },
        },
      },
    },
  } as ChartOptions<"bar">;

  const height = Math.min(
    windowDimensions.height - 90, // 最大でも"Made with"表示が潰れない
    Math.min(windowDimensions.height, windowDimensions.width) * 2, // アス比2を超える場合はストップ
    Math.max(windowDimensions.height, windowDimensions.width), // 長辺がでかい場合（いらんかも）
    600 // PC表示の場合を想定、そんなにでかくても見ずらい
  );

  return (
    <Box h={height} w={"calc(100vw - 32px)"} minW={300} maxW={770} minH={450}>
      <Bar options={barOptions_stacked} data={view} />
    </Box>
  );
}
