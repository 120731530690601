import { useEffect, useRef } from "react";

import lottie, { AnimationItem } from "lottie-web";

interface LottieProps {
  animationData: any;
  onComplete: (args: any) => void;
  loop: boolean;
  style?: any;
}

export const Lottie = ({
  animationData,
  loop,
  onComplete,
  style,
}: LottieProps) => {
  const element = useRef<HTMLDivElement>(null);
  const lottieInstance = useRef<AnimationItem>();

  useEffect(() => {
    if (element.current) {
      lottieInstance.current = lottie.loadAnimation({
        animationData,
        container: element.current,
        loop: loop,
      });
      lottieInstance.current.addEventListener("complete", onComplete);
      return () => {
        lottieInstance.current?.removeEventListener("complete", onComplete);
        lottieInstance.current?.destroy();
      };
    }
  }, []);

  return <div style={style} ref={element}></div>;
};
