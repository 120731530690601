import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { Route, Switch } from "wouter";
import App from "./App";
import { Create } from "./Create";
import "./firebase";
import "./index.css";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import jaCommon from "./locales/ja/translation.json";
import enCommon from "./locales/en/translation.json";

const detector = new LanguageDetector(null, {
  order: ["navigator"],
});

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      ja: { translation: jaCommon },
      en: { translation: enCommon },
    },
    nsSeparator: false,
    keySeparator: false,
    fallbackLng: "en",
    returnEmptyString: false,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

const setFillHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};
window.addEventListener("resize", setFillHeight);
setFillHeight();

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

const theme = extendTheme({
  initialColorMode: "light",
  useSystemColorMode: false,
  colors: {
    brand: {
      "50": "#EFF4F6",
      "100": "#D1DFE6",
      "200": "#B4CBD5",
      "300": "#96B6C5",
      "400": "#79A2B4",
      "500": "#5B8DA4",
      "600": "#497183",
      "700": "#375562",
      "800": "#243842",
      "900": "#121C21"
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <ChakraProvider theme={theme}>
    <Switch>
      <Route path="/" component={Create} />
      <Route path="/r/:surveyid" component={App} />
    </Switch>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
