import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { getFirestore, Unsubscribe } from "@firebase/firestore";
import {
  collection,
  doc,
  onSnapshot,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import React, { useEffect, useMemo, useRef, useState } from "react";
import useSWRImmutable from "swr/immutable";
import "./App.css";
import { appendResult } from "./calc";
import { Survey } from "./Survey";
import { PageDefintion, Response } from "./type";

const fetcher = (url: string) => fetch(url).then((res) => res.json());

function App() {
  const [pd, setPd] = useState<PageDefintion | null>(null);
  const unsub = useRef<Unsubscribe>();
  const id = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );

  const [res, setRes] = useState<number[]>();
  const { data, error } = useSWRImmutable(`/api/result/${id}`, fetcher);

  const calculated = useMemo(() => {
    if (pd && res && data) {
      return appendResult(pd, [res], 0, data);
    }
    return data;
  }, [data, res, pd]);

  useEffect(() => {
    const f = async () => {
      const db = getFirestore();
      unsub.current = onSnapshot(doc(db, "pageDef", id), (doc) => {
        const pd = doc.data() as PageDefintion;
        setPd(pd);
        if (pd.summary.length > 0) {
          document.title = `${pd.summary} - SurveyZero`;
        }
        if (doc.exists() && unsub.current) {
          unsub.current();
          unsub.current = undefined;
        }
      });
    };
    f();
  }, []);

  const setResponse = async (res: number[]) => {
    const fpPromise = FingerprintJS.load();
    const fpres = await fpPromise.then((fp) => fp.get());
    const visitorId = fpres.visitorId;

    const db = getFirestore();
    const pageDefRef = doc(collection(db, "pageDef", id, "response"));
    await setDoc(pageDefRef, {
      visitorId: visitorId,
      data: res,
      createdAt: serverTimestamp(),
    } as Response);
    setRes(res);
  };
  if (pd) {
    return (
      <Survey pageDefinition={pd} setResponse={setResponse} data={calculated} />
    );
  } else {
    return <></>;
  }
}

export default App;
