import { FormControl, FormHelperText } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Box, Center, Flex, HStack, Stack } from "@chakra-ui/layout";
import { Icon } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/textarea";
import { default as React, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { arrayMove, List } from "react-movable";
import { QuestionDefinition } from "./type";

function useFocusControl() {
  const ref = useRef(new Map<number, HTMLInputElement | HTMLTextAreaElement>());
  const focusProps = (index: number) => ({
    onKeyDown({ keyCode }: { keyCode: number }) {
      if (keyCode === 13 || keyCode === 40) {
        // Enter (not IME) or Down
        focusNextInput(index);
      }
      if (keyCode === 38) {
        focusPrevInput(index);
      }
    },
    ref(element: HTMLInputElement | HTMLTextAreaElement | null) {
      if (element) ref.current.set(index, element);
      else ref.current.delete(index);
    },
  });

  const focus = (index: number) => {
    ref.current.get(index)?.focus();
  };

  const focusNextInput = (index: number) => {
    const sortedIndices = Array.from(ref.current.keys()).sort();
    const nextIndex = sortedIndices[sortedIndices.indexOf(index) + 1];
    if (typeof nextIndex === "number") ref.current.get(nextIndex)?.focus();
  };

  const focusPrevInput = (index: number) => {
    const sortedIndices = Array.from(ref.current.keys()).sort();
    const nextIndex = sortedIndices[sortedIndices.indexOf(index) - 1];
    if (typeof nextIndex === "number") ref.current.get(nextIndex)?.focus();
  };

  return [focusProps, focus] as const;
}

const DragIcon = () => {
  return (
    <svg viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M7,19V17H9V19H7M11,19V17H13V19H11M15,19V17H17V19H15M7,15V13H9V15H7M11,15V13H13V15H11M15,15V13H17V15H15M7,11V9H9V11H7M11,11V9H13V11H11M15,11V9H17V11H15M7,7V5H9V7H7M11,7V5H13V7H11M15,7V5H17V7H15Z"
      />
    </svg>
  );
};

const DragHorizontal = () => {
  return (
    <svg viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M3,15V13H5V15H3M3,11V9H5V11H3M7,15V13H9V15H7M7,11V9H9V11H7M11,15V13H13V15H11M11,11V9H13V11H11M15,15V13H17V15H15M15,11V9H17V11H15M19,15V13H21V15H19M19,11V9H21V11H19Z"
      />
    </svg>
  );
};

export function CreateQ({
  qid,
  value,
  onChange,
  isDragged,
}: {
  qid: number;
  value: QuestionDefinition;
  onChange: (qd: QuestionDefinition) => void;
  isDragged: boolean;
}) {
  const { t, i18n } = useTranslation();
  const [ctl, focus] = useFocusControl();
  useEffect(() => {
    if (value.choices.length < 3) return;
    focus(value.choices.length - 1);
  }, [value.choices.length]);

  const q = value.heading;
  const onChangeQ = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (event.target.value.length > qlimit) return;
      onChange({ ...value, heading: event.target.value });
    },
    [value, onChange]
  );

  function setR(index: number, event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.length > rlimit) return;
    const c = value.choices.concat();
    c[index] = event.target.value;
    onChange({ ...value, choices: c });
  }

  const onChangeR =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setR(index, event);
    };
  const rlimit = 30;
  const qlimit = 140;

  return (
    <Box
      bg="white"
      boxShadow={isDragged ? "xl" : undefined}
      borderWidth="1px"
      p={3}
      rounded={"md"}
    >
      <Center data-movable-handle mt={-2} mb={1} _hover={{ cursor: "move" }}>
        <Icon fontSize={20} color={"grey"}>
          <DragHorizontal></DragHorizontal>
        </Icon>
      </Center>
      <Stack direction="column" spacing={2}>
        <FormControl>
          <Textarea
            value={q}
            onChange={onChangeQ}
            placeholder={`${t("質問")}${qid + 1}${
              qid === 0 ? "" : t("（オプション）")
            }`}
            variant="filled"
            {...ctl(-1)}
          />
          <FormHelperText
            fontSize={"10px"}
            position={"absolute"}
            bottom={1}
            right={1}
            textAlign="right"
          >
            {q.length}/{qlimit}
          </FormHelperText>
        </FormControl>

        <Flex>
          <Stack direction={"column"} flex={1} spacing={2}>
            <List
              values={value.choices}
              onChange={({ oldIndex, newIndex }) =>
                onChange({
                  ...value,
                  choices: arrayMove(value.choices, oldIndex, newIndex),
                })
              }
              renderList={({ children, props }) => (
                <Stack direction={"column"} flex={1} spacing={2} {...props}>
                  {children}
                </Stack>
              )}
              renderItem={({ value, props, index, isDragged }) => (
                <div {...props}>
                  <FormControl>
                    <HStack>
                      <Icon
                        data-movable-handle
                        fontSize={20}
                        color={"grey"}
                        mr={-2}
                        ml={-1}
                        _hover={{ cursor: "move" }}
                      >
                        <DragIcon />
                      </Icon>
                      <Input
                        value={value}
                        onChange={onChangeR(index!)}
                        placeholder={`${t("回答")}${index! + 1}${
                          index! > 1 ? t("（オプション）") : ""
                        }`}
                        variant="filled"
                        {...ctl(index!)}
                      />
                    </HStack>
                    <FormHelperText
                      fontSize={"10px"}
                      position={"absolute"}
                      bottom={1}
                      right={1}
                      textAlign="right"
                    >
                      {value.length}/{rlimit}
                    </FormHelperText>
                  </FormControl>
                </div>
              )}
            />
            {value.choices.length < 6 && (
              <FormControl>
                <HStack>
                  <Input
                    ml={4}
                    onFocus={() => {
                      onChange({ ...value, choices: [...value.choices, ""] });
                    }}
                    placeholder={t(`回答を追加`)}
                    variant="filled"
                    {...ctl(99)}
                  />
                </HStack>
                <FormHelperText
                  fontSize={"10px"}
                  position={"absolute"}
                  bottom={1}
                  right={1}
                  textAlign="right"
                >
                  0/{rlimit}
                </FormHelperText>
              </FormControl>
            )}
          </Stack>
        </Flex>
      </Stack>
    </Box>
  );
}
