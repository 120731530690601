import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Flex,
  keyframes,
  Stack,
  Text,
  useBoolean,
  useTheme,
} from "@chakra-ui/react";
import { ChartData } from "chart.js";
import { AnimatePresence, motion } from "framer-motion";
import { replaceColor } from "lottie-colorify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Autoplay, Keyboard, Mousewheel, Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import animationData from "./done.json";
import { Lottie } from "./Lottie";
import { Result } from "./Result";
import { SimpleResult } from "./SimpleResult";
import { Page, PageDefintion, QuestionDefinition } from "./type";

interface SurveyProps {
  pageDefinition: PageDefintion;
  setResponse: Function;
  data: ChartData<"bar">[];
}
export function Survey({ pageDefinition, setResponse, data }: SurveyProps) {
  const theme = useTheme();
  const response = useRef<number[]>([]);
  let [page, setPage] = useState<Page>({ phase: "survey", num: -1 });
  let [current, setCurrent] = useState<QuestionDefinition>({
    heading: "",
    choices: [],
  });
  const [initialized, setInitialized] = useBoolean(false);
  const [blink, setBlink] = useBoolean(false);
  const [blinkNum, setBlinkNum] = useState(-1);

  useEffect(() => {
    if (initialized) return;
    const milDiff =
      new Date().getTime() - pageDefinition.createdAt.toDate().getTime();
    if (milDiff / 1000 / 60 / 60 / 24 > 7) {
      setPage({ ...page, phase: "result", num: page.num + 1 });
      return;
    }
    setCurrent(pageDefinition.questions[page.num + 1]);
    setPage({ ...page, num: page.num + 1 });
    setInitialized.on();
  }, [initialized]);

  const onClick = useCallback(
    (i: number) => () => {
      if (current.choices.length <= i || i < 0 || !Number.isInteger(i)) return;
      setBlinkNum(i);
      response.current.push(i);
      next();
    },
    [current, response, setBlinkNum]
  );

  const keyFunction = useCallback(
    (event: any) => {
      if (event.keyCode >= 65 && event.keyCode <= 90) {
        onClick(event.keyCode - 65)();
      }
    },
    [onClick]
  );

  useEffect(() => {
    window.addEventListener("keydown", keyFunction, false);
    return () => {
      window.removeEventListener("keydown", keyFunction, false);
    };
  }, [current]);

  const next = async () => {
    switch (page.phase) {
      case "survey":
        setBlink.on();
        await new Promise((resolve) => setTimeout(resolve, 700));
        if (page.num + 1 < pageDefinition.questions.length) {
          setCurrent(pageDefinition.questions[page.num + 1]);
          setPage({ ...page, num: page.num + 1 });
        } else {
          setPage({ ...page, phase: "send", num: page.num + 1 });
        }
        break;
      case "send":
        await setResponse(response.current);
        setPage({ ...page, phase: "result", num: page.num + 1 });
        break;
    }
  };

  const moveUp = {
    animate: {
      y: 0,
      opacity: 1,
    },
    initial: {
      y: 100,
      opacity: 0,
    },
    exit: {
      y: -100,
      opacity: 0,
    },
    transition: {
      duration: 0.5,
    },
  };

  const opacity = {
    animate: {
      opacity: 1,
    },
    initial: {
      opacity: 0,
    },
    exit: {
      opacity: 0,
    },
    transition: {
      duration: 0.3,
    },
  };

  const animationKeyframes = keyframes`
  0% { background-color: ${theme.colors.brand[100]}; }
  100% { background-color: white}; }
`;

  const animation = `${animationKeyframes} 0.3s ease 2`;

  return (
    <Center className="sz-top-container">
      <Box w={"100vw"} maxW={800} minW={350}>
        <AnimatePresence exitBeforeEnter initial={false}>
          {page.phase === "survey" && page.num >= 0 && (
            <motion.div
              key={page.phase + page.num}
              {...moveUp}
              onAnimationComplete={() => {
                setBlink.off();
              }}
            >
              <Box p={2}>
                <Stack direction="column" spacing={2}>
                  <Text fontSize="xl">{current.heading}</Text>
                  {current.choices.map((c, i) => {
                    return (
                      <Button
                        animation={
                          blink && blinkNum === i ? animation : undefined
                        }
                        backgroundColor="white"
                        _hover={{
                          "@media (hover: hover)": { background: "brand.50" },
                        }}
                        position={"relative"}
                        whiteSpace={"normal"}
                        fontWeight="normal"
                        key={i}
                        colorScheme="brand"
                        variant="outline"
                        onClick={onClick(i)}
                      >
                        <Center
                          backgroundColor={"white"}
                          visibility={{ sm: "visible", base: "hidden" }}
                          position={"absolute"}
                          right={1}
                          w="30px"
                          h="30px"
                          borderWidth="1px"
                          borderRadius="md"
                        >
                          {String.fromCharCode(65 + i)}
                        </Center>
                        {c}
                      </Button>
                    );
                  })}
                  <MadeWith />
                </Stack>
              </Box>
            </motion.div>
          )}
          {page.phase === "send" && (
            <motion.div key={page.phase + page.num} {...opacity}>
              <AbsoluteCenter>
                <Lottie
                  onComplete={next}
                  animationData={replaceColor(
                    "#2BB857",
                    theme.colors.brand[500],
                    animationData
                  )}
                  style={{
                    height: "calc(100vh - 100px)",
                    width: "100vw",
                    maxWidth: "500px",
                  }}
                  loop={false}
                />
              </AbsoluteCenter>
            </motion.div>
          )}
          {page.phase === "result" && (
            <motion.div key={page.phase + page.num} {...opacity}>
              <Box p={2}>
                {data && data.length !== 0 && (
                  <ErrorBoundary
                    fallback={<div>回答結果が正常に取得できませんでした</div>}
                  >
                    {pageDefinition.questions.length === 1 ? (
                      <Stack>
                        <SimpleResult
                          title={pageDefinition.questions[0].heading}
                          data={data[0]}
                        ></SimpleResult>
                        <MadeWith />
                      </Stack>
                    ) : (
                      <Swiper
                        direction={"horizontal"}
                        pagination={{ clickable: true }}
                        mousewheel={true}
                        keyboard={true}
                        autoplay={{ delay: 3000, disableOnInteraction: true }}
                        modules={[Pagination, Mousewheel, Keyboard, Autoplay]}
                      >
                        {data.map((d, i) => {
                          return (
                            <SwiperSlide key={i}>
                              <Stack>
                                <Result
                                  axis={pageDefinition.questions[0].heading}
                                  title={
                                    pageDefinition.questions[i + 1].heading
                                  }
                                  data={d}
                                ></Result>
                                <MadeWith />
                              </Stack>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    )}
                  </ErrorBoundary>
                )}
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </Center>
  );
}

function MadeWith() {
  return (
    <Box>
      <Flex>
        <Flex flex={1}></Flex>
        <Box as="button">
          <a href="https://survey0.dev" target="_blank" rel="noreferrer">
            <img
              alt="SurveyZero logo"
              src="/images/madewith.svg"
              width="120"
              height="30"
            ></img>
          </a>
        </Box>
      </Flex>
    </Box>
  );
}
